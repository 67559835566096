define("discourse/plugins/docker_manager/discourse/components/docker-manager/progress-bar", ["exports", "@glimmer/component", "@ember/template", "discourse/helpers/concat-class", "@ember/template-factory", "@ember/component"], function (_exports, _component, _template, _concatClass, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ProgressBar extends _component.default {
    get active() {
      return parseInt(this.args.percent, 10) !== 100;
    }
    get barStyle() {
      let percent1 = parseInt(this.args.percent, 10);
      if (percent1 > 100) {
        percent1 = 100;
      }
      return (0, _template.htmlSafe)(`width: ${percent1}%`);
    }
  }
  _exports.default = ProgressBar;
  _class = ProgressBar;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class={{concatClass "progress-bar" (if this.active "active")}}>
        <div class="progress-bar-inner" style={{this.barStyle}}></div>
      </div>
    
  */
  {
    "id": "jR8g5Q7t",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"progress-bar\",[52,[30,0,[\"active\"]],\"active\"]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"progress-bar-inner\"],[15,5,[30,0,[\"barStyle\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/docker_manager/discourse/components/docker-manager/progress-bar.js",
    "scope": () => [_concatClass.default],
    "isStrictMode": true
  }), _class);
});